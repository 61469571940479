import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { UnpackPromise, UnpackApiList, UnpackArray } from "@src/utils/types"
import { components } from "./schema"
import { DynamicFilter } from "./filters"

export enum UserInvitationPolicy {
  Undefined = 0,
  Anyone = 1,
  OnlyPlatformUsers = 2,
  OnlyCommunityUsers = 3,
  None = 4, // only community admins can invite
}

export enum UserJoinCommunitySetting {
  JoinWithoutApproval = 0,
  JoinWithApproval = 1,
  JoinWithInvitation = 2,
}

export enum TaskUnlock {
  Calendar = 0,
  Freestyle = 1,
  Sequential = 2,
  Stagegate = 3,
}

export enum DevelopmentStageType {
  None = 0,
  Default = 1,
  CustomType = 2,
}

export enum CommunityType {
  Public = 0,
  Private = 1,
  Hybrid = 2,
}

export enum CommunityUserSortBy {
  None,
  Name,
  Comments,
  Likes,
  Followers,
  Activity,
}

export type CommunityUserFilter = {
  skip?: number
  take?: number
  communityId: number
  communityCircleId?: number | null
  circleFilter?: DynamicFilter<number>
  name?: string | null
  orderBy?: CommunityUserSortBy
  countryId?: number | null
  countryFilter?: DynamicFilter<number>
  interestId?: number | null
  industryFilter?: DynamicFilter<number>
  skillId?: number | null
  skillFilter?: DynamicFilter<number>
  sustainableDevelopmentGoalId?: number | null
  marketExpertiseId?: number | null
  userMarketsExpertisesFilter?: DynamicFilter<number>
  tags?: string[] | null
  tagFilter?: DynamicFilter<string>
  companyFilter?: DynamicFilter<string>
}

export const communityKeys = {
  all: ["community"],
  overview: (id?: number) => [...communityKeys.all, "overview", id, "overview"],
  users: (filters: CommunityUserFilter) => [
    ...communityKeys.all,
    "users",
    filters,
  ],
}

export const getCommunityOverview = async (id: number) => {
  const data = await client.GET("/api/Community/GetOverview", {
    params: {
      query: { id },
    },
  })

  return data
}

export type CommunityOverview = UnpackPromise<
  ReturnType<typeof getCommunityOverview>
>

export const getCommunityOverviewQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityOverview,
    queryKeys: communityKeys.overview(id),
    unsafeQueryFnArgs: [id],
  })

export type CommunityEditInfo = components["schemas"]["CommunityEditInfoDto"]

export const saveCommunityInfo = async (
  communityEditInfo: CommunityEditInfo,
) => {
  const data = await client.POST("/api/Community/SaveInfo", {
    body: communityEditInfo,
  })

  return data
}

export type SaveCommunityInfoResponse = UnpackPromise<
  ReturnType<typeof saveCommunityInfo>
>

export const getCommunityUsers = async (filters: CommunityUserFilter) => {
  const data = await client.POST("/api/Community/GetUserList", {
    body: filters,
  })

  return data
}

export type CommunityUserList = UnpackPromise<
  ReturnType<typeof getCommunityUsers>
>

export type CommunityUser = UnpackArray<
  UnpackApiList<CommunityUserList["users"]>
>

export const getCommunityUsersQuery = (filters: CommunityUserFilter) =>
  safeQueryOptions({
    queryFn: getCommunityUsers,
    queryKeys: communityKeys.users(filters),
    unsafeQueryFnArgs: [filters],
  })

export type CommunityEditAbout = components["schemas"]["CommunityEditAboutDto"]

export const saveCommunityAbout = async (aboutInfo: CommunityEditAbout) => {
  const data = await client.POST("/api/Community/SaveAbout", {
    body: aboutInfo,
  })

  return data
}

export type SaveCommunityAboutResponse = UnpackPromise<
  ReturnType<typeof saveCommunityAbout>
>
