import { Table, flexRender } from "@tanstack/react-table"
import { clsx } from "clsx"

export type DesktopTableProps<T> = {
  table: Table<T>
  stickyColumn?: boolean
}

const styles = {
  table: [
    "w-full bg-white px-8 pb-2",
    "border-separate border-spacing-0 rounded-xl",
  ],
  th: [
    "z-10 bg-white p-4 first:pl-0",
    "border-b border-pearl-lighter",
    "whitespace-nowrap text-left text-paragraph-medium font-semibold leading-none text-black",
  ],
  td: [
    "z-10 p-4 py-3.5 first:pl-0",
    "border-b border-pearl-lighter bg-white",
    "group-last:border-none",
    "whitespace-nowrap text-left text-paragraph-medium leading-none text-black",
  ],
  sticky: [
    "sticky left-8 z-20",
    "after:content-[''] after:w-[1px] after:h-3/4 after:bg-pearl-light",
    "after:absolute after:right-0 after:top-1/2 after:-translate-y-1/2",
    "before:content-[''] before:w-8 before:h-[calc(100%+2px)] before:bg-white",
    "before:absolute before:-left-8 before:top-0",
  ],
}
export const DesktopTable = <T extends object>({
  table,
  stickyColumn,
}: DesktopTableProps<T>) => {
  return (
    <div className="relative overflow-x-auto rounded-xl border border-pearl-lighter bg-white">
      <table className={clsx(styles.table)}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  className={clsx(
                    styles.th,
                    stickyColumn && index === 0 && styles.sticky,
                    "relative",
                  )}
                  style={{
                    width: header.column.getSize() || "100%",
                  }}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="group">
              {row.getVisibleCells().map((cell, index) => (
                <td
                  key={cell.id}
                  className={clsx(
                    styles.td,
                    stickyColumn && index === 0 && styles.sticky,
                    "relative",
                  )}
                  style={{
                    width: cell.column.getSize() || "100%",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
