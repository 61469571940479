import { useForm, Validator } from "@tanstack/react-form"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { lazy, Suspense } from "react"
import { t, Trans } from "@lingui/macro"

import { z } from "zod"
import { zodValidator } from "@tanstack/zod-form-adapter"

import { Input } from "@src/components/atoms/Input"
import { Button } from "@src/components/atoms/Button"
import { Field, Label, Description } from "@src/components/atoms/Fieldset"
import { ImageUpload } from "@src/components/molecules/ImageUpload"
import { PageContainer } from "@src/components/atoms/PageContainer"
import { Skeleton } from "@src/components/atoms/Skeleton"
import {
  saveCommunityInfo,
  saveCommunityAbout,
  communityKeys,
  CommunityEditInfo,
  CommunityOverview,
} from "@src/api/community"
import { uploadFile } from "@src/api/file"

const Editor = lazy(() =>
  import("@src/components/organisms/Editor").then((module) => ({
    default: module.Editor,
  })),
)

type GeneralSettingsFormValues = {
  name: string | null | undefined
  shortDescription: string | null | undefined
  mainImage: {
    url: string | null | undefined
    blob: File | null | undefined
  }
  logoImage: {
    url: string | null | undefined
    blob: File | null | undefined
  }
  description: string | null | undefined
}

type ZodValidator = Validator<
  unknown,
  z.ZodType<unknown, z.ZodTypeDef, unknown>
>

const updateCommunityMutationFn = async ({
  data,
  formValues,
}: {
  data: CommunityOverview
  formValues: GeneralSettingsFormValues
}) => {
  const newCommunityInfo: CommunityEditInfo = {
    id: data.id,
    name: formValues.name,
    shortDescription: formValues.shortDescription,
    mainImage: data.mainImage,
    logoImage: data.logoImage,
    description: formValues.description,
    countryId: data.countryId,
    city: data.city,
    menuLogoImage: data.menuLogoImage,
    communityTags: data.communityTags,
    hideParagraphsUntilDate: data.hideParagraphsUntilDate,
    generalSettingsHasLockTags: data.generalSettingsHasLockTags,
    defaultProjectFilterTag: data.defaultProjectFilterTag,
    allowTasks: data.allowTasks,
    hasLandingPage: data.hasLandingPage,
  }

  if (formValues.mainImage.blob) {
    const url = await uploadFile({
      file: formValues.mainImage.blob,
      folder: "community_image",
    })

    newCommunityInfo.mainImage = `/${url}`
  }

  if (formValues.logoImage.blob) {
    const url = await uploadFile({
      file: formValues.logoImage.blob,
    })

    newCommunityInfo.logoImage = `/${url}`
  }

  await saveCommunityInfo(newCommunityInfo)
  await saveCommunityAbout({
    id: data.id,
    description: formValues.description,
  })
}

export type GeneralSettingsFormProps = {
  initialData: CommunityOverview
}

export const GeneralSettingsForm = ({
  initialData,
}: GeneralSettingsFormProps) => {
  const queryClient = useQueryClient()

  const saveCommunityMutation = useMutation({
    mutationFn: updateCommunityMutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: communityKeys.overview(initialData.id),
      })
    },
  })

  const validators = {
    name: {
      onBlur: z
        .string()
        .min(3, t`The community name must be at least 3 characters`)
        .max(80, t`The community name must be under 80 characters`),
    },
  }

  const form = useForm<GeneralSettingsFormValues, ZodValidator>({
    defaultValues: {
      name: initialData?.name,
      shortDescription: initialData?.shortDescription,
      mainImage: {
        url: initialData?.mainImage,
        blob: null as File | null | undefined,
      },
      logoImage: {
        url: initialData?.logoImage,
        blob: null as File | null | undefined,
      },
      description: initialData?.description,
    },
    validatorAdapter: zodValidator(),
    onSubmit: async (values) => {
      if (initialData) {
        saveCommunityMutation.mutate({
          data: initialData,
          formValues: values.value,
        })
      }
    },
  })

  const handleCancel = () => {
    form.reset()
  }

  return (
    <div className="flex flex-col gap-4 lg:gap-6">
      <header className="flex items-center justify-end gap-2">
        <Button variant="secondary" small onClick={handleCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit]) => (
            <Button
              small
              type="submit"
              disabled={!canSubmit || saveCommunityMutation.isPending}
              onClick={form.handleSubmit}
            >
              <Trans>Save Changes</Trans>
            </Button>
          )}
        />
      </header>
      <section>
        <PageContainer className="m-auto max-w-[800px]">
          <form
            className="m-auto"
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
              form.handleSubmit()
            }}
          >
            <form.Field
              name="mainImage"
              children={(field) => (
                <ImageUpload
                  label={t`Cover image`}
                  description={t`Use a landscape image of max 1 MB and at least 400px.`}
                  name="mainImage"
                  value={{
                    url: field.state.value.url,
                  }}
                  buttonText="Upload"
                  imageContainerClassname="aspect-3/1"
                  onChange={(imageDetails) => {
                    field.handleChange({
                      blob: imageDetails?.blob,
                      url: null,
                    })
                  }}
                />
              )}
            />

            <form.Field
              name="logoImage"
              children={(field) => (
                <ImageUpload
                  label={t`Logo image`}
                  description={t`Use a square image of max 1 MB and at least 200px.`}
                  name="logoImage"
                  className="mt-6"
                  imageContainerClassname="w-48 aspect-square"
                  value={{
                    url: field.state.value.url,
                  }}
                  buttonText="Upload"
                  onChange={(imageDetails) => {
                    field.handleChange({
                      blob: imageDetails?.blob,
                      url: null,
                    })
                  }}
                />
              )}
            />

            <form.Field
              name="name"
              validators={validators.name}
              children={(field) => (
                <Field className="mt-6 flex-col">
                  <Label htmlFor="name">
                    <Trans>Community Name</Trans>
                  </Label>
                  <Description>
                    <Trans>
                      Try to keep it under 50 characters. 80 is the maximum
                      allowed.
                    </Trans>
                  </Description>
                  <Input
                    name="name"
                    id="name"
                    onChange={(e) => field.handleChange(e.target.value)}
                    error={field.state.meta.errors.join(", ")}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                  />
                </Field>
              )}
            />

            <form.Field
              name="shortDescription"
              children={(field) => (
                <Field className="mt-6 flex-col">
                  <Label htmlFor="short-description">
                    <Trans>Short description</Trans>
                  </Label>
                  <Description>
                    <Trans>
                      Appears next to the logo and the name. State the purpose
                      of the community.
                    </Trans>
                  </Description>
                  <Input
                    name="shortDescription"
                    id="short-description"
                    onChange={(e) => field.handleChange(e.target.value)}
                    value={field.state.value}
                  />
                </Field>
              )}
            />

            {/* Maybe move the suspense in the editor component? And lazy import the ckedito module there */}
            <Suspense fallback={<Skeleton className="h-[400px] w-full" />}>
              <form.Field
                name="description"
                children={(field) => (
                  <Field className="mt-6 flex-col">
                    <Label htmlFor="description">
                      <Trans>Description</Trans>
                    </Label>
                    <Description>
                      <Trans>An about page for your community</Trans>
                    </Description>
                    <Editor
                      value={field.state.value}
                      communityId={initialData.id || 0}
                      onChange={(value) => {
                        field.handleChange(value)
                      }}
                      className="max-h-[400px] overflow-auto rounded-xl border border-pearl-lighter px-5 py-2 focus:border-pearl-light"
                    />
                  </Field>
                )}
              />
            </Suspense>
          </form>
        </PageContainer>
      </section>
    </div>
  )
}
