import {
  Switch as HeadlessSwitch,
  type SwitchProps as HeadlessSwitchProps,
} from "@headlessui/react"
import clsx from "clsx"

import { Label, Field } from "@src/components/atoms/Fieldset"

export type SwitchProps = {
  checked: boolean
  label?: string
  className?: string
} & HeadlessSwitchProps

export const Switch = ({ checked, label, ...props }: SwitchProps) => {
  return (
    <Field className="flex items-center gap-2">
      <HeadlessSwitch
        checked={checked}
        className={clsx(
          "relative inline-flex h-7 w-14 items-center rounded transition-colors",
          checked ? "bg-blue" : "bg-white",
        )}
        {...props}
      >
        <span className="absolute inset-0 flex items-center justify-between px-1 text-xs text-white">
          <span
            className={clsx(
              "text-white",
              checked ? "opacity-100" : "opacity-0",
            )}
          >
            All
          </span>
          <span
            className={clsx("text-blue", checked ? "opacity-0" : "opacity-100")}
          >
            Any
          </span>
        </span>
        <span
          className={clsx(
            "inline-block size-5 transform rounded-sm transition-transform",
            checked ? "translate-x-8 bg-white" : "translate-x-1 bg-blue",
          )}
        />
      </HeadlessSwitch>
      <Label className="text-paragraph-medium">{label}</Label>
    </Field>
  )
}
