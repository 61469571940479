import { Trans } from "@lingui/macro"
import { clsx } from "clsx"

import { MenuItem } from "@src/components/organisms/Menu/Common/MenuItem"
import { useMenus, MenuItemType } from "@src/hooks/useMenus"

export const CommunityMenu = () => {
  const { communityMenu, adminMenu } = useMenus()

  return (
    <aside className="hidden border-r border-pearl-lighter lg:flex">
      <section className="border-r border-pearl-lighter p-2">
        <nav>
          {communityMenu.map(({ name, link, icon }: MenuItemType) => (
            <MenuItem
              key={`menu-item-${name}`}
              link={link}
              icon={icon}
              className="my-2 rounded-xl p-2"
            />
          ))}
        </nav>
      </section>
      <section className="p-2">
        <header
          className={clsx([
            "flex items-center justify-between",
            "pb-2 pl-5 pt-4",
            "text-paragraph-medium uppercase text-pearl-light",
          ])}
        >
          <Trans>Admin Panel</Trans>
        </header>
        <nav>
          {adminMenu.map(({ name, link, icon }: MenuItemType) => (
            <MenuItem
              key={name}
              name={name}
              link={link}
              icon={icon}
              className="my-2 gap-6 px-8 py-2"
            />
          ))}
        </nav>
      </section>
    </aside>
  )
}
