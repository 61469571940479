import { t } from "@lingui/macro"
import { useCallback, useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import {
  saveCircleToCirclePermission,
  circleByCirclePermissionKeys,
  CircleToCirclePermissionByCommunity,
} from "@src/api/permission"
import { CommunityCircleDetail } from "@src/api/circle"
import { IconButton } from "@src/components/atoms/IconButton"
import { Icon } from "@src/components/atoms/Icon"
import { Permission } from "./types"
import {
  isColumnComplete,
  getCircleToCirclePermission,
  findCircleStatus,
} from "./utils"

interface IUseCircleToCirclePermissionsRows {
  communityId: number | undefined
  communityCircles: CommunityCircleDetail[] | undefined
  permissions: CircleToCirclePermissionByCommunity[] | undefined
}

export const useCircleToCirclePermissionsRows = ({
  communityId,
  communityCircles,
  permissions,
}: IUseCircleToCirclePermissionsRows) => {
  const queryClient = useQueryClient()

  const { mutate: saveCircleToCirclePermissionMutation } = useMutation({
    mutationFn: saveCircleToCirclePermission,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: circleByCirclePermissionKeys.permissionsList(communityId),
      })
    },
  })

  const circleToCircleCommunity: Record<string, string> = {
    canSendMessages: t`Can send messages`,
    canScheduleMeetings: t`Can schedule meetings`,
  }

  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({
    canSendMessages: false,
    canScheduleMeetings: false,
  })

  const toggleRowExpansion = (key: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [key]: !prev[key],
    }))
  }

  const isColumnCompleteMemoized = useCallback(
    ({
      circleId,
      permissionKey,
    }: {
      circleId?: number
      permissionKey: "canSendMessages" | "canScheduleMeetings"
    }) =>
      isColumnComplete({
        permissions,
        communityCircles,
        circleId,
        permissionKey,
      }),
    [permissions, communityCircles],
  )

  const getCircleToCirclePermissionMemoized = useCallback(
    ({
      circleIdFrom,
      circleIdTo,
    }: {
      circleIdFrom?: number
      circleIdTo?: number
    }) =>
      getCircleToCirclePermission({
        permissions,
        circleIdFrom,
        circleIdTo,
      }),
    [permissions],
  )

  const findCircleStatusMemoized = useCallback(
    ({
      circleIdFrom,
      circleIdTo,
      permissionKey,
    }: {
      circleIdFrom?: number
      circleIdTo?: number
      permissionKey: "canSendMessages" | "canScheduleMeetings"
    }) =>
      findCircleStatus({
        permissions,
        circleIdFrom,
        circleIdTo,
        permissionKey,
      }),
    [permissions],
  )

  const createExpandedRows = (
    permissionKey: "canSendMessages" | "canScheduleMeetings",
  ) =>
    communityCircles?.map((circle) => {
      const row: Permission = {
        name: <span className="pl-9">{circle.name}</span>,
      }

      communityCircles.forEach((innerCircle: CommunityCircleDetail) => {
        row[innerCircle.name || ""] = findCircleStatusMemoized({
          circleIdFrom: circle.id,
          circleIdTo: innerCircle.id,
          permissionKey,
        }) ? (
          <IconButton
            onClick={() => {
              const currentPermission = getCircleToCirclePermissionMemoized({
                circleIdFrom: circle.id,
                circleIdTo: innerCircle.id,
              })

              saveCircleToCirclePermissionMutation({
                ...currentPermission,
                [permissionKey]: false,
              })
            }}
            variant="text"
            disabled={circle.name === "Admins"}
            icon="check_circle"
            className="text-green"
            size="large"
          />
        ) : (
          <IconButton
            onClick={() => {
              const currentPermission = getCircleToCirclePermissionMemoized({
                circleIdFrom: circle.id,
                circleIdTo: innerCircle.id,
              })
              saveCircleToCirclePermissionMutation({
                ...currentPermission,
                [permissionKey]: true,
              })
            }}
            variant="text"
            disabled={circle.name === "Admins"}
            icon="cancel"
            className="text-pearl-light"
            size="large"
          />
        )
      })
      return row
    }) || []

  const canSendMessagesRows = createExpandedRows("canSendMessages")
  const canScheduleMeetingsRows = createExpandedRows("canScheduleMeetings")

  const communityRows = Object.entries(circleToCircleCommunity).map(
    ([key, label]) => {
      const isExpanded = expandedRows[key]
      const row: Permission = {
        name: (
          <div
            className="flex cursor-pointer items-center gap-3"
            onClick={(ev) => {
              ev.stopPropagation()
              toggleRowExpansion(key)
            }}
          >
            <IconButton
              icon="keyboard_arrow_right"
              variant="text"
              size="large"
              className={`transition-transform ${
                isExpanded ? "rotate-45" : ""
              }`}
            />
            {label}
          </div>
        ),
      }

      communityCircles?.forEach((circle) => {
        if (circle.name) {
          row[circle.name] = isColumnCompleteMemoized({
            circleId: circle.id,
            permissionKey: key as "canSendMessages" | "canScheduleMeetings",
          }) ? (
            <Icon icon="check_circle" className="text-green" size="large" />
          ) : (
            <Icon
              icon="stroke_partial"
              className="text-pearl-light"
              size="large"
            />
          )
        }
      })

      return row
    },
  )

  return communityRows.flatMap((row, index) => [
    row,
    ...(expandedRows[Object.keys(circleToCircleCommunity)[index]]
      ? index === 0
        ? canSendMessagesRows
        : canScheduleMeetingsRows
      : []),
  ])
}
