import { Fragment } from "react"
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react"
import clsx from "clsx"

import { Field, Label, ErrorMessage } from "@src/components/atoms/Fieldset"
import { Icon } from "@src/components/atoms/Icon"

const styles = {
  base: [
    "relative flex items-center justify-between w-full rounded-xl border bg-white", // Base styles
    "text-paragraph-medium", // Typography
    "focus:outline-none appearance-none", // Hide default focus styles
    "data-[invalid]:border-red", // Invalid state
  ],
  primary: [
    "border-pearl-lighter focus:border-pearl-light bg-transparent text-black",
  ],
  secondary: [
    "bg-pearl-lighter/10 focus:border-pearl-lighter border-pearl-lighter/10 text-black-light",
  ],
  size: (small: boolean) =>
    small ? ["py-1 pl-3 pr-1 gap-2"] : ["py-2 pl-5 pr-2 gap-4"],
}

type Option = {
  id: number
  label: string
  group?: string
  icon?: string
}

export type SelectProps = {
  variant?: "primary" | "secondary"
  options: Option[]
  label?: string
  placeholder?: string
  value?: Option | null
  error?: string
  className?: string
  small?: boolean
  onSelect: (option: Option) => void
  disabled?: boolean
}

export const Select = ({
  variant = "primary",
  label,
  placeholder,
  error,
  value,
  small = false,
  options,
  className,
  onSelect,
  disabled,
}: SelectProps) => {
  return (
    <Field className={clsx("flex-col", className)}>
      <Label htmlFor={label}>{label}</Label>

      <Listbox
        value={value}
        onChange={onSelect}
        disabled={disabled}
        invalid={!!error}
      >
        <ListboxButton
          className={clsx(styles.base, styles[variant], styles.size(small))}
        >
          <span>
            {value ? (
              value.label
            ) : (
              <span className="text-gray">{placeholder}</span>
            )}
          </span>
          <Icon
            className="group pointer-events-none text-black"
            icon="keyboard_arrow_down"
            size="large"
          />
        </ListboxButton>

        <ListboxOptions
          anchor="bottom"
          transition
          className={clsx(
            "z-20 grid flex-1 gap-0.5",
            "my-2 w-[var(--button-width)] p-2",
            "border border-pearl-lighter bg-white",
            "font-semibold text-black",
          )}
        >
          {options.map((option, index) => (
            <Fragment key={option.id}>
              {index > 0 && options[index - 1].group !== option.group && (
                <hr className="mx-4 my-1 border-t border-pearl-lighter" />
              )}
              <ListboxOption
                value={option}
                className={clsx(
                  "group flex items-center gap-2 overflow-auto",
                  "cursor-pointer select-none rounded-md px-4 py-2 hover:bg-blue-light/50",
                  "text-paragraph-medium font-light text-black",
                  { "bg-blue-light/50": option.id === value?.id },
                )}
              >
                {option.icon && (
                  <Icon
                    className="text-black"
                    icon={option.icon}
                    size="large"
                  />
                )}
                {option.label}
              </ListboxOption>
            </Fragment>
          ))}
        </ListboxOptions>
      </Listbox>
      <ErrorMessage>{error}</ErrorMessage>
    </Field>
  )
}
