import { Trans } from "@lingui/macro"

import { IconButton } from "@src/components/atoms/IconButton"
import { Select } from "@src/components/atoms/Select"

export type PaginationProps = {
  pageIndex: number
  pageCount: number
  pageSize: number
  pageSizeOptions: number[]
  onPageSizeChange: (pageSize: number) => void
  handlePreviousPage: () => void
  handleFirstPage: () => void
  handleNextPage: () => void
  handleLastPage: () => void
}

export const Pagination = ({
  pageIndex,
  pageCount,
  pageSize,
  pageSizeOptions,
  onPageSizeChange,
  handleFirstPage,
  handlePreviousPage,
  handleNextPage,
  handleLastPage,
}: PaginationProps) => {
  return (
    <div className="mt-4 flex items-center justify-between">
      <div className="flex items-center gap-1 text-paragraph-medium text-black">
        <Trans>Results per page</Trans>
        <Select
          small
          value={{ id: pageSize, label: pageSize.toString() }}
          options={pageSizeOptions.map((size) => ({
            id: size,
            label: size.toString(),
          }))}
          onSelect={({ id }) => onPageSizeChange(id)}
        />
      </div>
      <div className="flex items-center text-paragraph-medium text-black">
        <Trans>
          {pageIndex + 1}-{pageIndex + pageSize} of {pageCount}
        </Trans>
      </div>
      <div className="flex items-center gap-1">
        <IconButton
          icon="keyboard_double_arrow_left"
          onClick={handleFirstPage}
          variant="text"
        />
        <IconButton
          icon="keyboard_arrow_left"
          onClick={handlePreviousPage}
          variant="text"
        />
        <IconButton
          icon="keyboard_arrow_right"
          onClick={handleNextPage}
          variant="text"
        />
        <IconButton
          icon="keyboard_double_arrow_right"
          onClick={handleLastPage}
          variant="text"
        />
      </div>
    </div>
  )
}
