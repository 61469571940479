import {
  getCommunityOverviewQuery,
  CommunityOverview,
} from "@src/api/community"
import { useParams } from "@tanstack/react-router"
import { useQuery } from "@tanstack/react-query"

export const permissions = ["admin-panel"] as const

const hasPermissions = (
  permission: (typeof permissions)[number],
  communityOverview: CommunityOverview | undefined,
) => {
  switch (permission) {
    case "admin-panel":
      return communityOverview?.canEditCommunityInfo
    default:
      return false
  }
}

export const usePermissions = () => {
  const { communityId } = useParams({ strict: false })
  const { data: communityOverview, isLoading } = useQuery(
    getCommunityOverviewQuery(parseInt(communityId as string)),
  )

  return {
    hasPermissions: (permission: (typeof permissions)[number]) =>
      hasPermissions(permission, communityOverview),
    isLoading,
  }
}
