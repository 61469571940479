import { useQuery } from "@tanstack/react-query"
import { CellContext, ColumnDef } from "@tanstack/react-table"
import { useNavigate } from "@tanstack/react-router"
import { format } from "date-fns"
import { Trans } from "@lingui/macro"

import { getCommunityFormsQuery } from "@src/api/form"
import { Table } from "@src/components/organisms/Table"
import { useCommunityId } from "@src/hooks/useCommunityId"
import { Button } from "@src/components/atoms/Button"

export interface FormTableItem {
  id: number | undefined
  name: string | null | undefined
  creationDate: string | null | undefined
}

export const Forms = () => {
  const communityId = useCommunityId()
  const navigate = useNavigate()

  const { data: forms, isLoading } = useQuery(
    getCommunityFormsQuery(communityId),
  )

  const columns: ColumnDef<FormTableItem>[] = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Creation Date",
      accessorKey: "creationDate",
    },
    {
      header: "Actions",
      accessorKey: "actions",
      cell: ({ row }: CellContext<FormTableItem, unknown>) => {
        const id = row.original.id

        return (
          <span className="flex h-4">
            <Button
              onClick={() => {
                navigate({
                  to: `/community/${communityId}/admin-panel/forms/${id}/view`,
                })
              }}
              className="pl-0"
              variant="text"
              icon="visibility"
              small
            >
              <Trans>View</Trans>
            </Button>
            <Button
              variant="text"
              icon="edit"
              small
              onClick={() => {
                navigate({
                  to: `/community/${communityId}/admin-panel/forms/${id}`,
                })
              }}
            >
              <Trans>Edit</Trans>
            </Button>
            <Button variant="text" icon="file_copy" small>
              <Trans>Duplicate</Trans>
            </Button>
            <Button variant="text" icon="delete" small>
              <Trans>Delete</Trans>
            </Button>
          </span>
        )
      },
    },
  ]

  const data =
    forms?.list?.map(({ id, name, creationDate }) => ({
      id,
      name,
      creationDate: format(
        new Date(creationDate || ""),
        "EEE dd-MM-yyyy HH:mm",
      ),
    })) || []

  return <Table columns={columns} data={data} loading={isLoading} />
}
