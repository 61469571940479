import { useCommunityId } from "@src/hooks/useCommunityId"
import { IconType } from "@src/config/icons"

export type MenuItemType = {
  name: string
  link: string
  icon: IconType
}

export const useMenus = () => {
  const communityId = useCommunityId()

  const communityMenu: MenuItemType[] = [
    {
      name: "Home",
      link: `/community/${communityId}`,
      icon: "home",
    },
    {
      name: "Forum",
      link: `/community/${communityId}/forum`,
      icon: "tooltip_2",
    },
    {
      name: "Members",
      link: `/community/${communityId}/members`,
      icon: "group",
    },
    {
      name: "Resources",
      link: `/community/${communityId}/resources`,
      icon: "folder_copy",
    },
    {
      name: "Events",
      link: `/community/${communityId}/events`,
      icon: "event",
    },
    {
      name: "Requests",
      link: `/community/${communityId}/request`,
      icon: "live_help",
    },
    {
      name: "Applications",
      link: `/community/${communityId}/applications`,
      icon: "page_info",
    },
  ]
  const adminMenu: MenuItemType[] = [
    {
      name: "General",
      link: `/community/${communityId}/admin-panel/community-settings`,
      icon: "tune",
    },
    {
      name: "Forms",
      link: `/community/${communityId}/admin-panel/forms`,
      icon: "description",
    },
    {
      name: "Programs",
      link: `/community/${communityId}/admin-panel/methodology`,
      icon: "lightbulb",
    },
    {
      name: "Applications",
      link: `/community/${communityId}/admin-panel/application-definitions`,
      icon: "bookmarks",
    },
    {
      name: "Startups",
      link: `/community/${communityId}/admin-panel/overview-projects`,
      icon: "rocket_launch",
    },
    {
      name: "Coaching",
      link: `/community/${communityId}/admin-panel/requests-status`,
      icon: "forum",
    },
    {
      name: "Permissions",
      link: `/community/${communityId}/admin-panel/circle-management`,
      icon: "encrypted",
    },
    {
      name: "Pages",
      link: `/community/${communityId}/admin-panel/pages`,
      icon: "home",
    },
    {
      name: "Statistics",
      link: `/community/${communityId}/admin-panel/statistics`,
      icon: "monitoring",
    },
  ]

  return { adminMenu, communityMenu }
}
