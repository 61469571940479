import { ColumnDef, CellContext } from "@tanstack/react-table"

import { Permission } from "./types"
import { CommunityCircleDetail } from "@src/api/circle"

interface IUseColumns {
  communityCircles: CommunityCircleDetail[] | undefined
}

export const useColumns = ({ communityCircles }: IUseColumns) => {
  return [
    {
      header: "Task permission",
      accessorKey: "name",
      cell: (info: CellContext<Permission, unknown>) => info.getValue(),
    },
    ...(communityCircles?.map((circle) => ({
      header: () => <span className="block text-center">{circle.name}</span>,
      accessorKey: circle.name || "",
      cell: (info: CellContext<Permission, unknown>) => (
        <span className="block text-center">{info.getValue() as string}</span>
      ),
    })) || []),
  ] as ColumnDef<Permission>[]
}
