import { useQuery } from "@tanstack/react-query"
import { getCommunityOverviewQuery } from "@src/api/community"
import { GeneralSettingsForm } from "@src/components/organisms/GeneralSettingsForm"
import { Skeleton } from "@src/components/atoms/Skeleton"

export type GeneralSettingsPageProps = {
  communityId: number
}

export const GeneralSettingsPage = ({
  communityId,
}: GeneralSettingsPageProps) => {
  const { data, isLoading } = useQuery(getCommunityOverviewQuery(communityId))

  return (
    <div>
      {data && <GeneralSettingsForm initialData={data} />}
      {isLoading && <Skeleton className="m-auto h-[100vh] max-w-[800px]" />}
    </div>
  )
}
