import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { UnpackPromise, UnpackArray } from "@src/utils/types"

export const circleKeys = {
  all: ["circles"],
  circleList: (id?: number) => [...circleKeys.all, "list", "community", id],
}

export const getCommunityCircles = async (id: number) => {
  const data = await client.GET("/api/CommunityCircle/GetByCommunity", {
    params: {
      query: {
        communityId: id,
      },
    },
  })

  return data
}

export type CommunityCircleDetail = UnpackArray<
  UnpackPromise<ReturnType<typeof getCommunityCircles>>
>

export const getCommunityCirclesQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityCircles,
    queryKeys: circleKeys.circleList(id),
    unsafeQueryFnArgs: [id],
  })

export const saveCommunityCircle = async (payload: CommunityCircleDetail) => {
  const data = await client.POST("/api/CommunityCircle/Save", {
    body: payload,
  })

  return data
}
