export const STAGING_URL = "app-new-stag.babele.co"

export const PROD_URL = "app-new.babele.co"

export const V1_APP_URL =
  import.meta.env.MODE === "production"
    ? "https://app.babele.co"
    : "https://app-stag.babele.co"

export const UPLOAD_FOLDERS = {
  resourceFiles: "resource_files",
  tabImages: "tab_image",
  businessPlanLogos: "businessplan_logo",
  discussionImages: "discussion_image",
  communityImages: "community_image",
  profilePictures: "profile_picture",
  customLogos: "custom_logo",
} as const

export type FileUploadFolder =
  (typeof UPLOAD_FOLDERS)[keyof typeof UPLOAD_FOLDERS]

export const UPLOAD_FOLDERS2 = [
  "resource_files",
  "tab_image",
  "businessplan_logo",
  "discussion_image",
  "community_image",
  "profile_picture",
  "custom_logo",
] as const
