import { createFileRoute } from "@tanstack/react-router"
import { GeneralSettingsPage } from "@src/components/pages/admin/GeneralSettings"

export const Route = createFileRoute(
  "/_auth/_community/community/$communityId/_admin-panel/admin-panel/community-settings",
)({
  component: CommunitySettingsRoute,
})

function CommunitySettingsRoute() {
  const { communityId } = Route.useParams()
  const parsedCommunityId = parseInt(communityId)

  return <GeneralSettingsPage communityId={parsedCommunityId} />
}
