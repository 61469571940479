import { lazy, Suspense } from "react"
import { useForm, Validator } from "@tanstack/react-form"
import { z } from "zod"
import { zodValidator } from "@tanstack/zod-form-adapter"
import { t, Trans } from "@lingui/macro"
import { useNavigate } from "@tanstack/react-router"

import { Button } from "@src/components/atoms/Button"
import { Input } from "@src/components/atoms/Input"
import { Icon } from "@src/components/atoms/Icon"

import { CommunityForm, FormDto } from "@src/api/form"
import { GenericFormQuestion } from "./Question"
import { DEFAULT_FORM_QUESTION } from "./Question/defaultData"
import { EmptyState } from "./EmptyState"

import {
  SortableList,
  SortableElement,
} from "@src/components/atoms/SortableList"

const Editor = lazy(() =>
  import("@src/components/organisms/Editor").then((module) => ({
    default: module.Editor,
  })),
)

export type ZodValidator = Validator<
  unknown,
  z.ZodType<unknown, z.ZodTypeDef, unknown>
>

type GenericFormProps = {
  initialForm: CommunityForm
  onSave: (form: FormDto) => void
}

export const GenericForm = ({ initialForm, onSave }: GenericFormProps) => {
  const navigate = useNavigate()

  const validators = {
    name: {
      onBlur: z
        .string()
        .min(3, t`The form name must be at least 3 characters`)
        .max(80, t`The form name must be under 80 characters`),
    },
  }

  const form = useForm<FormDto, ZodValidator>({
    defaultValues: initialForm,
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      onSave(value)
    },
  })

  return (
    <form
      className="m-auto"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
    >
      <div className="flex flex-col gap-6">
        <header className="flex items-center justify-end gap-2">
          <Button
            variant="secondary"
            small
            onClick={() => {
              navigate({ to: "/" })
            }}
          >
            <Trans>Cancel</Trans>
          </Button>
          <form.Subscribe
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            children={([canSubmit]) => (
              <Button small type="submit" disabled={!canSubmit}>
                <Trans>Save Changes</Trans>
              </Button>
            )}
          />
        </header>
        <section className="w-full rounded-xl bg-white p-6 lg:max-w-[1256px]">
          <form.Field
            name="name"
            validators={validators.name}
            children={(field) => (
              <Input
                name="name"
                placeholder="Name"
                error={field.state.meta.errors.join(", ")}
                variant="ternary"
                onChange={(e) => field.handleChange(e.target.value)}
                value={field.state.value}
                onBlur={field.handleBlur}
              />
            )}
          />
          <div className="my-4 h-[1px] w-full bg-pearl-lighter"></div>

          <Suspense fallback={<div>Loading editor...</div>}>
            <form.Field
              name="description"
              children={(field) => (
                <Editor
                  className="rounded-xl bg-ghost-white"
                  placeholder="Write a compelling description to explain to users how they should dill the form"
                  value={field.state.value}
                  communityId={241}
                  onChange={(value) => {
                    field.handleChange(value)
                  }}
                />
              )}
            />
          </Suspense>

          <div className="my-4 h-[1px] w-full bg-pearl-lighter"></div>

          <div className="flex flex-col gap-6">
            <form.Field
              name="questions"
              mode="array"
              children={(questionsField) => {
                const questions = questionsField.state.value || []

                const items =
                  questions?.map((q, i) => ({
                    id: i,
                    element: (
                      <GenericFormQuestion
                        key={i}
                        index={q.questionOrder || 0}
                        form={form}
                      />
                    ),
                  })) ?? []

                const onDragEnd = (orderedItems: SortableElement[]) => {
                  const newQuestionsOrder: FormDto["questions"] = []

                  for (let i = 0; i < orderedItems.length; i++) {
                    const question = Object.assign(
                      {},
                      questions[orderedItems[i].id],
                    )

                    if (question) {
                      question.questionOrder = i
                      newQuestionsOrder[i] = question
                    }
                  }

                  form.setFieldValue("questions", newQuestionsOrder)
                }

                return questions.length ? (
                  <SortableList items={items} onDragEnd={onDragEnd} />
                ) : (
                  <EmptyState />
                )
              }}
            />

            <button
              className="flex w-full items-center justify-center rounded-xl bg-alice-blue py-2"
              onClick={(e) => {
                e.preventDefault()
                const questions = form.getFieldValue("questions")
                const newQuestion = Object.assign({}, DEFAULT_FORM_QUESTION, {
                  questionOrder: questions?.length || 0,
                })

                if (Array.isArray(questions)) {
                  form.setFieldValue("questions", [...questions, newQuestion])
                } else {
                  form.setFieldValue("questions", [newQuestion])
                }
              }}
            >
              <Icon icon="add_row_below" size="large2x" className="text-blue" />
            </button>
          </div>
        </section>
      </div>
    </form>
  )
}
