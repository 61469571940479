import { useParams } from "@tanstack/react-router"
import { useQuery, useMutation } from "@tanstack/react-query"

import {
  getApplicationFormQuery,
  saveApplicationForm,
  FormDto,
} from "@src/api/form"

import { GenericForm } from "@src/components/organisms/GenericForm"
import {
  prepareFormSaveStep1,
  prepareFormSaveStep2,
} from "@src/components/organisms/GenericForm/parser"
import { Skeleton } from "@src/components/atoms/Skeleton"

export const FormPage = () => {
  const params = useParams({ strict: false })
  const formId = params.formId ? parseInt(params.formId, 10) : undefined

  const { data: form } = useQuery(getApplicationFormQuery(formId))

  const { mutate: saveApplicationFormMutation } = useMutation({
    mutationFn: (payload: FormDto) => saveApplicationForm(formId || 0, payload),
  })

  const onSave = async (initialForm: FormDto) => {
    const formStep1 = prepareFormSaveStep1(initialForm)
    return saveApplicationFormMutation(formStep1, {
      onSuccess: (responseStep1) => {
        const formStep2 = prepareFormSaveStep2(initialForm, responseStep1)

        return saveApplicationFormMutation(formStep2)
      },
    })
  }

  return form ? (
    <GenericForm initialForm={form} onSave={onSave} />
  ) : (
    <Skeleton className="h-[100vh]" />
  )
}
