import { useQuery } from "@tanstack/react-query"

import { getCircleToCirclePermissionsByCommunityQuery } from "@src/api/permission"
import { getCommunityCirclesQuery } from "@src/api/circle"
import { Table } from "@src/components/organisms/Table"
import { useCommunityId } from "@src/hooks/useCommunityId"

import { useCirclePermissionsRows } from "./useCirclePermissionsRows"
import { useCircleToCirclePermissionsRows } from "./useCircleToCirclePermissionsRows"
import { useColumns } from "./useColumns"
import { Permission } from "./types"

export const Permissions = () => {
  const communityId = useCommunityId()

  const { data: permissions, isLoading: permissionsLoading } = useQuery(
    getCircleToCirclePermissionsByCommunityQuery(communityId),
  )

  const { data: communityCircles, isLoading: communityCirclesLoading } =
    useQuery(getCommunityCirclesQuery(communityId))

  const circlePermissionRows = useCirclePermissionsRows({
    communityId,
    communityCircles,
  })

  const circleToCirclePermissionRows = useCircleToCirclePermissionsRows({
    communityId,
    communityCircles,
    permissions,
  })

  const data: Permission[] = [
    ...circlePermissionRows,
    ...circleToCirclePermissionRows,
  ]

  const columns = useColumns({
    communityCircles,
  })

  return (
    <Table
      columns={columns}
      data={data}
      stickyColumn
      forceDesktopView
      loading={permissionsLoading || communityCirclesLoading}
    />
  )
}
