import { lazy, Suspense, useState } from "react"
import { FormApi, ReactFormApi } from "@tanstack/react-form"
import { z } from "zod"
import { t } from "@lingui/macro"
import { useSortable } from "@dnd-kit/sortable"
import { clsx } from "clsx"
import { CSS } from "@dnd-kit/utilities"

import { FormDto, FormQuestionAnswerType } from "@src/api/form"
import { Input } from "@src/components/atoms/Input"
import { Select } from "@src/components/atoms/Select"
import { Checkbox } from "@src/components/atoms/Checkbox"
import { IconButton } from "@src/components/atoms/IconButton"
import { getAnswerTypes } from "./utils"
import { GenericFormQuestionOptions } from "./QuestionOptions"
import { GenericFormConditionalLogic } from "./ConditionalLogic"
import { ZodValidator } from "../"

type GenericFormQuestionProps = {
  index: number
  form: FormApi<FormDto, ZodValidator> & ReactFormApi<FormDto, ZodValidator>
}

const Editor = lazy(() =>
  import("@src/components/organisms/Editor").then((module) => ({
    default: module.Editor,
  })),
)

export const GenericFormQuestion = ({
  index,
  form,
}: GenericFormQuestionProps) => {
  const conditionalLogicsList =
    form.getFieldValue(`questions[${index}].formQuestionConditionalLogicList`)
      ?.length || 0

  const [withConditionalLogic, setWithConditionalLogic] = useState(
    conditionalLogicsList > 0,
  )
  const answerTypes = getAnswerTypes()

  const validators = {
    title: {
      onBlur: z
        .string()
        .min(2, t`The question name must be at least 2 characters`)
        .max(80, t`The question name must be under 80 characters`),
    },
    answerType: {
      onChange: z.number().min(1, t`This field is required`),
    },
  }
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: index,
  })

  return (
    <div
      key={index}
      ref={setNodeRef}
      className={clsx(
        "relative flex flex-col gap-3 rounded-xl bg-alice-blue px-12 py-7",
        isDragging && "z-20 shadow-lg",
      )}
      style={{ transform: CSS.Translate.toString(transform), transition }}
    >
      <div className="flex flex-col gap-2 md:flex-row">
        <form.Field
          name={`questions[${index}].title`}
          validators={validators.title}
          children={(field) => (
            <Input
              name={`questions[${index}].title`}
              className="md:w-2/3"
              error={field.state.meta.errors.join(", ")}
              placeholder={t`Question Name`}
              variant="secondary"
              onChange={(e) => {
                console.log("triggering onchange")
                field.handleChange(e.target.value)
                // Forcing re-render of the entire form. Otherwise the re-render is limtied to this particular field
                // and we need the entire questions array to be re-rendered so the drop&drag picks up this new state
                // change
                form.setFieldValue("questions", form.getFieldValue("questions"))
              }}
              value={field.state.value}
              onBlur={field.handleBlur}
            />
          )}
        />
        <form.Field
          name={`questions[${index}].answerType`}
          validators={validators.answerType}
          children={(field) => (
            <Select
              className="md:w-1/3"
              placeholder={t`Question Type`}
              error={field.state.meta.errors.join(", ")}
              variant="secondary"
              onSelect={({ id }: { id: FormQuestionAnswerType }) => {
                field.handleChange(id)
                // Forcing re-render of the entire form. Otherwise the re-render is limtied to this particular field
                // and we need the entire questions array to be re-rendered so the drop&drag picks up this new state
                // change
                form.setFieldValue("questions", form.getFieldValue("questions"))
              }}
              value={answerTypes.find(({ id }) => id === field.state.value)}
              options={answerTypes}
            />
          )}
        />
      </div>

      <Suspense fallback={<div>Loading editor...</div>}>
        <form.Field
          name={`questions[${index}].description`}
          children={(field) => (
            <Editor
              className="rounded-xl bg-white placeholder:text-pearl-dark"
              value={field.state.value}
              placeholder={t`Description`}
              communityId={241}
              onChange={(e) => {
                field.handleChange(e)
                // Forcing re-render of the entire form. Otherwise the re-render is limtied to this particular field
                // and we need the entire questions array to be re-rendered so the drop&drag picks up this new state
                // change
                form.setFieldValue("questions", form.getFieldValue("questions"))
              }}
            />
          )}
        />
      </Suspense>

      <GenericFormQuestionOptions form={form} index={index} />

      <div className="flex flex-col gap-2 md:flex-row md:gap-8">
        <form.Field
          name={`questions[${index}].mandatoryAnswer`}
          children={(field) => (
            <Checkbox
              checked={!!field.state.value}
              onChange={(e) => {
                field.handleChange(e)
                // Forcing re-render of the entire form. Otherwise the re-render is limtied to this particular field
                // and we need the entire questions array to be re-rendered so the drop&drag picks up this new state
                // change
                form.setFieldValue("questions", form.getFieldValue("questions"))
              }}
              label={t`Mandatory question`}
            />
          )}
        />
        <Checkbox
          checked={withConditionalLogic}
          onChange={(e) => {
            // Forcing re-render of the entire form. Otherwise the re-render is limtied to this particular field
            // and we need the entire questions array to be re-rendered so the drop&drag picks up this new state
            // change
            form.setFieldValue("questions", form.getFieldValue("questions"))
            setWithConditionalLogic(e)
          }}
          label={t`Conditional Logic`}
        />
        <form.Field
          name={`questions[${index}].isFilterable`}
          children={(field) => (
            <Checkbox
              checked={!!field.state.value}
              onChange={(e) => {
                // Forcing re-render of the entire form. Otherwise the re-render is limtied to this particular field
                // and we need the entire questions array to be re-rendered so the drop&drag picks up this new state
                // change
                form.setFieldValue("questions", form.getFieldValue("questions"))
                field.handleChange(e)
              }}
              label={t`Filterable`}
            />
          )}
        />
      </div>

      <GenericFormConditionalLogic
        form={form}
        index={index}
        show={withConditionalLogic}
      />

      <IconButton
        icon="delete"
        size="largex"
        variant="text"
        className="absolute right-1 top-16 text-pearl-dark"
        onClick={() => form.removeFieldValue("questions", index)}
      />
      <IconButton
        icon="drag_indicator"
        size="largex"
        variant="text"
        className="absolute left-3 top-16 text-pearl-dark"
        {...listeners}
        {...attributes}
      />
    </div>
  )
}
