import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core"
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import clsx from "clsx"

export type SortableElement = {
  id: number
  element: React.ReactNode
  // This is potentially useful for debugging purposes, you can pass the information
  // used to create the React element
  meta?: unknown
}

export type SortableListProps = {
  items: SortableElement[]
  // This will be called with the new order of items
  onDragEnd: (orderedItems: SortableElement[]) => void
  className?: string
}

export const SortableList = ({
  items,
  onDragEnd,
  className,
}: SortableListProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const onDragEndHandler = (event: DragEndEvent) => {
    const { active, over } = event
    if (typeof active?.id === "number" && typeof over?.id === "number") {
      onDragEnd(arrayMove(items, active?.id, over?.id))
    }
  }

  return (
    <div className={clsx("relative z-10", className)}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEndHandler}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map(({ element }) => element)}
        </SortableContext>
      </DndContext>
    </div>
  )
}
