import { useEffect } from "react"
import { FormApi, ReactFormApi } from "@tanstack/react-form"
import { Trans } from "@lingui/macro"

import { FormDto } from "@src/api/form"
import { Input } from "@src/components/atoms/Input"
import { Button } from "@src/components/atoms/Button"
import { IconButton } from "@src/components/atoms/IconButton"
import { ZodValidator } from "../"
import { hasMultipleOptions } from "./utils"
import { DEFAULT_FORM_QUESTION_OPTION } from "../Question/defaultData"

type GenericFormQuestionProps = {
  index: number
  form: FormApi<FormDto, ZodValidator> & ReactFormApi<FormDto, ZodValidator>
}

export const GenericFormQuestionOptions = ({
  index,
  form,
}: GenericFormQuestionProps) => {
  const questionOptions = form.useStore(
    (state) => state.values?.questions?.[index].questionOptions,
  )

  const answerType = form.useStore(
    (state) => state.values?.questions?.[index].answerType,
  )

  const hasOptions = hasMultipleOptions(answerType)

  useEffect(() => {
    if (hasOptions && !questionOptions?.length) {
      form.setFieldValue(`questions[${index}].questionOptions`, [
        DEFAULT_FORM_QUESTION_OPTION,
      ])
    }
  }, [hasOptions, form, questionOptions, index])

  return !hasOptions ? null : (
    <>
      <form.Field
        name={`questions[${index}].questionOptions`}
        mode="array"
        children={(optionsField) => {
          return (
            <>
              {!optionsField.state.value?.length
                ? ""
                : optionsField.state.value?.map((_, i) => (
                    <div key={i} className="relative flex gap-2">
                      <IconButton
                        icon="drag_indicator"
                        size="large"
                        variant="text"
                        className="text-pearl-dark"
                      />

                      <form.Field
                        name={`questions[${index}].questionOptions[${i}].questionOption`}
                        children={(field) => (
                          <Input
                            name={`questions[${index}].questionOptions[${i}].questionOption`}
                            className="md:w-1/3"
                            variant="secondary"
                            small
                            onChange={(e) => {
                              field.handleChange(e.target.value)
                              // Forcing re-render of the entire form. Otherwise the re-render is limtied to this particular field
                              // and we need the entire questions array to be re-rendered so the drop&drag picks up this new state
                              // change
                              form.setFieldValue(
                                "questions",
                                form.getFieldValue("questions"),
                              )
                            }}
                            value={field.state.value}
                            onBlur={field.handleBlur}
                          />
                        )}
                      />

                      <IconButton
                        icon="delete"
                        size="large"
                        variant="text"
                        className="text-pearl-dark"
                        onClick={() => {
                          form.removeFieldValue(
                            `questions[${index}].questionOptions`,
                            index,
                          )
                          // Forcing re-render of the entire form. Otherwise the re-render is limtied to this particular field
                          // and we need the entire questions array to be re-rendered so the drop&drag picks up this new state
                          // change
                          form.setFieldValue(
                            "questions",
                            form.getFieldValue("questions"),
                          )
                        }}
                      />
                    </div>
                  ))}
              <Button
                variant="text"
                small
                className="self-start"
                onClick={() => {
                  const questionOptions = form.getFieldValue(
                    `questions[${index}].questionOptions`,
                  )

                  form.setFieldValue(
                    `questions[${index}].questionOptions`,
                    Array.isArray(questionOptions)
                      ? [...questionOptions, DEFAULT_FORM_QUESTION_OPTION]
                      : [DEFAULT_FORM_QUESTION_OPTION],
                  )

                  // Forcing re-render of the entire form. Otherwise the re-render is limtied to this particular field
                  // and we need the entire questions array to be re-rendered so the drop&drag picks up this new state
                  // change
                  form.setFieldValue(
                    "questions",
                    form.getFieldValue("questions"),
                  )
                }}
              >
                <Trans>Add Option</Trans>
              </Button>
            </>
          )
        }}
      />
    </>
  )
}
