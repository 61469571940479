import { ReactNode } from "react"
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react"
import { clsx } from "clsx"

import { Icon } from "@src/components/atoms/Icon"

export type AccordionProps = {
  title: ReactNode
  children: ReactNode
}

export const Accordion = ({ title, children }: AccordionProps) => {
  return (
    <Disclosure
      as="div"
      className={clsx(
        "w-full rounded-xl bg-white",
        "border border-pearl-lighter",
      )}
    >
      {() => (
        <>
          <DisclosureButton
            className={clsx(
              "flex w-full items-center justify-between gap-2 py-2 pl-4 pr-2",
              "text-paragraph-medium font-normal text-black",
            )}
          >
            {title}
            <Icon icon="more_vert" className="text-pearl-dark" />
          </DisclosureButton>

          <DisclosurePanel className="border-t border-pearl-lighter px-3 py-5">
            {children}
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  )
}
