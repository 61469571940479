import { Outlet } from "@tanstack/react-router"

import { Header } from "@src/components/organisms/Header"
import { CommunityMenu } from "@src/components/organisms/Menu/CommunityMenu"
import { MobileMenu } from "@src/components/organisms/Menu/MobileMenu"
import { CommunityAdminBreadcrumbs } from "@src/components/organisms/Breadcrumbs/CommunityAdminBreadcrumbs"

export const CommunityLayout = () => {
  return (
    <div className="min-h-dvh w-full">
      <Header />
      <div className="flex min-h-[calc(100dvh-84px)]">
        <CommunityMenu />
        <main className="h-[calc(100vh-97px)] w-full overflow-auto bg-pearl p-4 lg:h-[calc(100vh-67px)] lg:max-w-[calc(100%-270px)] lg:p-6">
          <CommunityAdminBreadcrumbs />
          <Outlet />
        </main>
      </div>
      <MobileMenu />
    </div>
  )
}
