import { t } from "@lingui/macro"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import {
  saveCommunityCircle,
  CommunityCircleDetail,
  circleKeys,
} from "@src/api/circle"
import { IconButton } from "@src/components/atoms/IconButton"
import { Permission } from "./types"

interface IUseCirclePermissionsRows {
  communityId: number | undefined
  communityCircles: CommunityCircleDetail[] | undefined
}

type PermissionKey =
  | "permissionRequestCanViewAllAndInviteAdvisor"
  | "permissionProjectCanCreate"
  | "permissionCanAccessToOverviewProject"
  | "permissionCanInviteToProjects"
  | "permissionCanApproveParagraph"
  | "permissionCanBeInvitedAsCoach"
  | "permissionCanCoachTeamMembers"

export const useCirclePermissionsRows = ({
  communityId,
  communityCircles,
}: IUseCirclePermissionsRows) => {
  const queryClient = useQueryClient()

  const { mutate: saveCommunityCircleMutation } = useMutation({
    mutationFn: saveCommunityCircle,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: circleKeys.circleList(communityId),
      })
    },
  })

  const permissionsMap: Record<PermissionKey, string> = {
    permissionRequestCanViewAllAndInviteAdvisor: t`Assign users to requests`,
    permissionProjectCanCreate: t`Create projects`,
    permissionCanAccessToOverviewProject: t`Access project dashboard`,
    permissionCanInviteToProjects: t`Manage project members`,
    permissionCanApproveParagraph: t`Approve activities`,
    permissionCanBeInvitedAsCoach: t`Can book coaching sessions`,
    permissionCanCoachTeamMembers: t`Schedule coaching sessions`,
  }

  return Object.entries(permissionsMap).map(([key, label]) => {
    const row: Permission = { name: label }

    communityCircles?.forEach((circle: CommunityCircleDetail) => {
      if (circle.name) {
        const hasPermission = circle[key as PermissionKey]
        row[circle.name] = hasPermission ? (
          <IconButton
            onClick={() => {
              saveCommunityCircleMutation({
                ...circle,
                [key]: false,
              })
            }}
            variant="text"
            disabled={circle.name === "Admins"}
            icon="check_circle"
            className="!text-green"
            size="large"
          />
        ) : (
          <IconButton
            onClick={() => {
              saveCommunityCircleMutation({
                ...circle,
                [key]: true,
              })
            }}
            variant="text"
            icon="cancel"
            className="!text-pearl-light"
            size="large"
          />
        )
      }
    })

    return row
  })
}
