import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { UnpackPromise, UnpackArray } from "@src/utils/types"

export const circleByCirclePermissionKeys = {
  all: ["circleByCirclePermissions"],
  permissionsList: (communityId?: number) => [
    ...circleByCirclePermissionKeys.all,
    "list",
    "permissions",
    communityId,
  ],
}

export const getCircleToCirclePermissionsByCommunity = async (
  communityId: number,
) => {
  const data = await client.GET(`/api/CircleToCirclePermission/ByCommunity`, {
    params: {
      query: { communityId },
    },
  })

  return data
}

export type CircleToCirclePermissionByCommunity = UnpackArray<
  UnpackPromise<ReturnType<typeof getCircleToCirclePermissionsByCommunity>>
>

export const getCircleToCirclePermissionsByCommunityQuery = (
  communityId?: number,
) =>
  safeQueryOptions({
    queryFn: getCircleToCirclePermissionsByCommunity,
    queryKeys: circleByCirclePermissionKeys.permissionsList(communityId),
    unsafeQueryFnArgs: [communityId],
  })

export const saveCircleToCirclePermission = async (
  payload: CircleToCirclePermissionByCommunity,
) => {
  const data = await client.POST(
    "/api/CircleToCirclePermission/SaveCommunityCircles",
    {
      body: payload,
    },
  )

  return data
}
