import { CircleToCirclePermissionByCommunity } from "@src/api/permission"
import { CommunityCircleDetail } from "@src/api/circle"

export const isColumnComplete = ({
  permissions,
  communityCircles,
  circleId,
  permissionKey,
}: {
  permissions: CircleToCirclePermissionByCommunity[] | undefined
  communityCircles: CommunityCircleDetail[] | undefined
  circleId?: number
  permissionKey: "canSendMessages" | "canScheduleMeetings"
}) => {
  const permissionCount = permissions?.filter(
    (perm) => perm.circleIdFrom === circleId && perm[permissionKey],
  ).length

  if (permissionCount) {
    return permissionCount >= (communityCircles?.length ?? 0) || false
  }

  return false
}

export const getCircleToCirclePermission = ({
  permissions,
  circleIdFrom,
  circleIdTo,
}: {
  permissions: CircleToCirclePermissionByCommunity[] | undefined
  circleIdFrom?: number
  circleIdTo?: number
}) =>
  permissions?.find(
    (c) => c.circleIdTo === circleIdFrom && c.circleIdFrom === circleIdTo,
  )

export const findCircleStatus = ({
  permissions,
  circleIdFrom,
  circleIdTo,
  permissionKey,
}: {
  permissions: CircleToCirclePermissionByCommunity[] | undefined
  circleIdFrom?: number
  circleIdTo?: number
  permissionKey: "canSendMessages" | "canScheduleMeetings"
}) =>
  getCircleToCirclePermission({
    permissions,
    circleIdFrom,
    circleIdTo,
  })?.[permissionKey]
