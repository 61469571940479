import {
  Checkbox as HeadlessCheckbox,
  type CheckboxProps as HeadlessCheckboxProps,
} from "@headlessui/react"

import { Label, Field } from "@src/components/atoms/Fieldset"

export type CheckboxProps = {
  checked: boolean
  label?: string
  className?: string
} & HeadlessCheckboxProps

export const Checkbox = ({ checked, label, ...props }: CheckboxProps) => {
  return (
    <Field className="flex items-center gap-2">
      <HeadlessCheckbox
        checked={checked}
        className="group block size-4 rounded border border-pearl-lighter bg-white p-0.5 focus:outline-none"
        {...props}
      >
        <div className="size-2.5 rounded-sm group-data-[checked]:bg-blue"></div>
      </HeadlessCheckbox>
      <Label className="text-paragraph-medium">{label}</Label>
    </Field>
  )
}
