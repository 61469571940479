import clsx from "clsx"

export type PageContainerProps = {
  className?: string
  children: React.ReactNode
}

export const PageContainer = ({ children, className }: PageContainerProps) => {
  return (
    <div
      className={clsx(
        "rounded-xl border border-pearl-lighter bg-white p-6",
        className,
      )}
    >
      {children}
    </div>
  )
}
