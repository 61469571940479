import { client } from "./client"
import { safeQueryOptions } from "@src/utils/query"
import { UnpackPromise } from "@src/utils/types"
import { components } from "./schema"

export enum FormQuestionAnswerType {
  RadioChoiceAnswer = 0,
  CheckBoxChoiceAnswer = 1,
  DropDownChoiceAnswer = 2,
  MultipleChoiceAnswer = 3,
  AccountMoneyAnswer = 4,
  CalendarAnswer = 5,
  ShortTextAnswer = 7,
  LongTextAnswer = 8,
  FileAnswer = 9,
  ScaleAnswer = 10,
  CountryAnswer = 11,
  TableAnswer = 12,
}

export enum FormQuestionConditionLogic {
  Equal = 1,
  NotEqual = 2,
  GreaterThan = 3,
  LessThan = 4,
  Contains = 5,
  DoesNotContain = 6,
  IsEmpty = 7,
  NotEmpty = 8,
}

export enum FormQuestionConditionalLogicType {
  None = 0,
  AnyConditional = 1,
  AllConditionals = 2,
}

export const formKeys = {
  all: ["forms"],
  communityForms: (id?: number) => [...formKeys.all, "list", "community", id],
  form: (id?: number) => [...formKeys.all, "form", id],
}

export const getCommunityForms = async (id: number) => {
  const data = await client.GET("/api/Community/{id}/Forms", {
    params: {
      path: { id },
    },
  })

  return data
}

export const getCommunityFormsQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getCommunityForms,
    queryKeys: formKeys.communityForms(id),
    unsafeQueryFnArgs: [id],
  })

export const getApplicationForm = async (id: number) => {
  const data = await client.GET("/api/ApplicationForm/{id}", {
    params: {
      path: { id },
    },
  })

  return data
}

export type CommunityForm = UnpackPromise<ReturnType<typeof getApplicationForm>>
export type FormDto = components["schemas"]["FormDto"]
export type FormQuestionDto = components["schemas"]["FormQuestionDto"]
export type FormQuestionOptionDto =
  components["schemas"]["FormQuestionOptionDto"]
export type FormQuestionConditionalLogicDto =
  components["schemas"]["FormQuestionConditionalLogicDto"]

export const getApplicationFormQuery = (id?: number) =>
  safeQueryOptions({
    queryFn: getApplicationForm,
    queryKeys: formKeys.form(id),
    unsafeQueryFnArgs: [id],
  })

export const saveApplicationForm = async (id: number, payload: FormDto) => {
  const data = await client.PUT("/api/ApplicationForm/{id}", {
    params: {
      path: { id },
    },
    body: payload,
  })

  return data
}
