import { IconType } from "@src/config/icons"
import { clsx } from "clsx"

import { sizeClasses } from "./utils"

export type IconProps = {
  icon: IconType
  size?: keyof typeof sizeClasses
  className?: string
}

export const Icon = ({ icon, size = "default", className }: IconProps) => {
  return (
    <span
      className={clsx([
        "material-symbols-outlined",
        "inline-flex items-center leading-none",
        sizeClasses[size],
        className,
      ])}
      role="img"
      aria-label={icon}
    >
      {icon}
    </span>
  )
}
