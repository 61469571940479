import { FormQuestionAnswerType } from "@src/api/form"
import { Privacy } from "@src/api/privacy"

export const DEFAULT_FORM_QUESTION = {
  id: 0,
  formId: 0,
  title: "",
  description: "",
  questionOrder: 0,
  mandatoryAnswer: true,
  answerType: FormQuestionAnswerType.ShortTextAnswer,
  answerSubtype: "",
  allowMultipleAnswers: false,
  privacyLevel: Privacy.Public,
}

export const DEFAULT_FORM_QUESTION_OPTION = {
  id: 0,
  questionOptionOrder: 0,
}

export const DEFAULT_FORM_QUESTION_CONDITIONAL_LOGIC_OPTION = {
  id: 0,
  conditionFormQuestionId: 0,
  conditionLogic: undefined,
  customValue: "",
  formQuestionOptionId: undefined,
}
