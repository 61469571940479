import { useState } from "react"
import clsx from "clsx"
import { FileInput, UploadedFile } from "@src/components/atoms/FileInput"
import { getFullImageUrl } from "@src/utils/image"

export type ImageDetails = {
  blob?: File | null
  name?: string | null
  url?: string | null
}

export type ImageUploadProps = {
  value?: ImageDetails | null
  label?: string
  description?: string
  onChange?: (imageDetails: ImageDetails | null) => void
  name: string
  buttonText?: string
  className?: string
  imageContainerClassname?: string
}

const styles = {
  base: [
    "relative flex items-center justify-center rounded-xl border-pearl-lighter bg-cover bg-center bg-no-repeat",
  ],
  border: (dashed: boolean) => (dashed ? ["border border-dashed"] : ["border"]),
}

export const ImageUpload = ({
  value,
  label,
  description,
  buttonText,
  onChange,
  name,
  className = "",
  imageContainerClassname = "",
}: ImageUploadProps) => {
  const initialImageUrl = value?.url ? getFullImageUrl(value.url) : ""
  const [backgroundImage, setBackgroundImage] =
    useState<string>(initialImageUrl)

  const onImageUpdate = (files: UploadedFile[]) => {
    if (files.length > 0) {
      const [file] = files

      if (file.file && file.fileName) {
        const clonedFile = new File([file.file], file.fileName, {
          type: file.file.type,
        })
        const imageUrl = URL.createObjectURL(clonedFile)
        setBackgroundImage(imageUrl)

        onChange?.({
          blob: clonedFile,
          name: file.fileName,
        })
      }
    } else {
      setBackgroundImage("")
      onChange?.(null)
    }
  }

  const classes = clsx(
    styles.base,
    styles.border(backgroundImage === ""),
    imageContainerClassname,
  )

  return (
    <div className={clsx(className, "flex flex-col gap-2")}>
      {label && <p className="text-paragraph text-black">{label}</p>}
      {description && (
        <p className="text-paragraph-medium text-black-light">{description}</p>
      )}
      <div
        className={classes}
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <FileInput
          multiple={false}
          onChange={(files) => onImageUpdate(files)}
          variant="translucent"
          showFileNames={false}
          small={true}
          text={buttonText}
          showRemoveAllFiles={true}
          name={name}
          accept="image/*"
          value={
            value
              ? [
                  {
                    fileName: value?.name || "",
                    existing: true,
                  },
                ]
              : undefined
          }
        />
      </div>
    </div>
  )
}
